import { Participant, Product, ProductCategory, Venue } from "@models/models";
import { Region } from "@medusajs/medusa";
import { amplifyGet, get } from "./fetch";

export const additionalStoreProductsRelations: string[] = [];
additionalStoreProductsRelations.push("options");
additionalStoreProductsRelations.push("type");
additionalStoreProductsRelations.push("featured_reviews");
additionalStoreProductsRelations.push("featured_reviews.review");
additionalStoreProductsRelations.push("featured_reviews.review.variant");
additionalStoreProductsRelations.push("categories");
additionalStoreProductsRelations.push("variants");
additionalStoreProductsRelations.push("variants.options");
additionalStoreProductsRelations.push("variants.prices");
additionalStoreProductsRelations.push("venueArrangement");
additionalStoreProductsRelations.push("venueArrangement.venue");
additionalStoreProductsRelations.push("venueArrangement.venue.city");
additionalStoreProductsRelations.push("variants.variantParticipants");
additionalStoreProductsRelations.push(
  "variants.variantParticipants.participant",
);
additionalStoreProductsRelations.push(
  "variants.variantParticipants.participant.contentBlocks",
);
additionalStoreProductsRelations.push(
  "variants.variantParticipants.participant.contentBlocks.contentBlock",
);
additionalStoreProductsRelations.push("variants.seatingChart");
additionalStoreProductsRelations.push("account");
additionalStoreProductsRelations.push("account.analytics_elements");
additionalStoreProductsRelations.push(
  "account.analytics_elements.analytics_element_type",
);
additionalStoreProductsRelations.push("productContentBlocks");
additionalStoreProductsRelations.push("productContentBlocks.contentBlock");

additionalStoreProductsRelations.push("brand");
additionalStoreProductsRelations.push("featured_products");
additionalStoreProductsRelations.push("featured_products.featured_product");

export async function getProductsBySlug(
  slug: string,
  { show_past_only }: { show_past_only?: boolean } = { show_past_only: false },
): Promise<{
  products: Product[];
  slugEntity: ProductCategory | Participant | Venue;
}> {
  if (!slug || slug === "null") {
    return {
      products: [],
      slugEntity: {
        id: "",
        name: "",
        handle: "",
        description: "",
        created_at: new Date(),
      },
    };
  }
  const products = await amplifyGet(
    `/store/slugs/${slug}/events?${show_past_only ? "show_past_only=1" : ""}`,
  );
  const slugEntity = await amplifyGet(`/store/slugs/${slug}`);
  return { products: products.data, slugEntity: slugEntity.data };
}

export async function getAllGiftCardsByRegion(): Promise<Product[]> {
  const regionsData: { regions: Region[] } = await get("/store/regions");
  let products: Product[] = [];
  for (const r of regionsData.regions) {
    const ps = await get(
      `/store/products?is_giftcard=true&limit=500&region_id=${r.id}`,
    );
    products = products.concat(ps.products);
  }
  return products;
}

export async function getProductById(id: string): Promise<Product> {
  const { product } = await get(
    `/store/products/${id}?expand=${additionalStoreProductsRelations.join(",")}`,
  );
  return product;
}

export async function getProductBySlug(slug: string): Promise<Product | null> {
  const productId = await getProductIdBySlug(slug);
  return productId ? await getProductById(productId) : null;
}

export async function getProductIdBySlug(slug: string): Promise<string> {
  const { data } = await get(`/store/product-ids/handle/${slug}`);
  return data;
}
